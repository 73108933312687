import { HOSPITAL_HOST } from '@/constants/legacy/constEnv'

const ROUTE_KEYS = {
  INDEX: '/',
  ACCOUNTS: 'accounts',
  ADD: 'add',
  ADD_INFO: 'add-info',
  AGREEMENT: 'agreement',
  ALL: 'all',
  APP_ONLY: 'app-only',
  BEFORE_PRIVACY_20220719: 'before-privacy-20220719',
  BEFORE_PRIVACY_20221004: 'before-privacy-20221004',
  BEFORE_PRIVACY_20230424: 'before-privacy-20230424',
  BEFORE_PRIVACY_20230628: 'before-privacy-20230628',
  BEFORE_PRIVACY_20230731: 'before-privacy-20230731',
  BEFORE_PRIVACY_20231031: 'before-privacy-20231031',
  BEFORE_PRIVACY_20231115: 'before-privacy-20231115',
  BEFORE_PRIVACY_20241201: 'before-privacy-20241201',
  BEFORE_LOCATION_PRIVACY_20220125: 'before-location-privacy-20220125',
  BEFORE_LOCATION_PRIVACY_20230424: 'before-location-privacy-20230424',
  BEST_SELLER: 'bestseller',
  BRANDS: 'brands',
  BUNDLED_LIST: 'bundled',
  CALLBACK: 'callback',
  CANCEL_RETURN_EXCHANGE: 'cancel-return-exchange',
  CART: 'cart',
  CATEGORY: 'category',
  CONFIRM_PASSWORD: 'confirm-password',
  COUPONS: 'coupons',
  CREATE: 'create',
  DELIVERY_ADDRESSES: 'delivery-addresses',
  DONE: 'done',
  EDIT: 'edit',
  EVENTS: 'events',
  EXCHANGE_REFUND_GUIDE: 'exchange-refund-guide',
  EMAIL_LOGIN: 'email-login',
  FAMILY: 'family',
  FIND: 'find',
  FIND_HOSPITALS: 'find-hospitals',
  FINISH: 'finish',
  FREQUENTLY_LIST: 'frequently-list',
  FRIENDS: 'friends',
  GUEST: 'guest',
  HOME: 'home',
  HOSPITAL: HOSPITAL_HOST,
  RESERVATIONS: 'reservations',
  HOSPITALS: 'hospitals',
  ID: 'id',
  INQUIRY: 'inquiry',
  INFO: 'info',
  INTRO: 'intro',
  INVITE: 'invite',
  JOIN: 'join',
  LOCATION_SERVICE: 'location-service',
  LOGIN: 'login',
  MALL: 'mall',
  MARKETING_AGREEMENT: 'marketing-agreement',
  MEMBERSHIP: 'membership',
  MILEAGES: 'mileages',
  MILEAGE: 'mileage',
  MIGRATE: 'migrate',
  MYPAGE: 'mypage',
  NEED_CONVERT: 'need-convert',
  OPTIONAL_PRIVACY_TO_COLLECT: 'optional-privacy-to-collect',
  ORDER: 'order',
  ORDERS: 'orders',
  OWNER: 'owner',
  PASSWORD: 'password',
  HISTORY: 'history',
  PAYMENT_GUARANTEE: 'payment-guarantee',
  POLICY: 'policy',
  POLICY_MARKETING: 'policy-marketing',
  PRIVACY: 'privacy',
  PRIVACY_PURPOSE: 'privacy-purpose',
  PROCESSED: 'processed',
  PROCESSING: 'processing',
  PRODUCTS: 'products',
  PROVIDE_3RD: 'provide3rd',
  REASON: 'reason',
  RECOMMEND: 'recommend',
  REDIRECT: 'redirect',
  REFUND_ACCOUNT: 'refund-account',
  REGISTER: 'register',
  REGULAR_PAYMENT: 'regular-payment',
  RELATED_LIST: 'related-list',
  REQUEST: 'request',
  RESET_PASSWORD: 'reset-password',
  RESULT: 'result',
  RETRIEVAL_ADDRESSES: 'retrieval-addresses',
  REVIEWS: 'reviews',
  SCRAP: 'scrap',
  SEARCH: 'search',
  SECTIONS: 'sections',
  SELLERS: 'sellers',
  SETTING: 'setting',
  SIGN_UP: 'sign-up',
  SNS_APPLE: 'sns/apple',
  SNS_KAKAO: 'sns/kakao',
  SNS_NAVER: 'sns/naver',
  SNS_LINK: 'sns-link',
  MY: 'my',
  TERMS: 'terms',
  SUBSCRIBE: 'subscribe',
  SUBSCRIBE_FINISH: 'subscribe-finish',
  CANCEL: 'cancel',
  TRANSACTION: 'transaction',
  UPDATE: 'update',
  VERSION: 'version',
  WITHDRAW: 'withdraw',
  WELCOME: 'welcome',
  WRITE: 'write',
  ZENDESK: 'zendesk',
  FITPET_PLUS_TERMS: 'fitpet-plus-terms',
  FITPET_PLUS_PRIVACY: 'fitpet-plus-privacy',
}

export const RouteKeyName = {
  BestSellerCategoryName: '[categoryName]',
  CategoriesRootCategoryId: '[rootCategoryId]',
  CategoriesSubCategoryId: '[subCategoryId]',
  BrandId: '[brandId]',
  ProductId: '[productId]',
  AdSlug: '[adSlug]',
  RootCategorySlug: '[rootCategorySlug]',
  SubCategorySlug: '[subCategorySlug]',
  PetType: '[pet-type]',
  Keyword: '[keyword]',
}

const ROUTE_HOME_BASE = `${ROUTE_KEYS.HOME}`
const ROUTE_ACCOUNTS_BASE = `${ROUTE_KEYS.ACCOUNTS}`
const ROUTE_ACCOUNTS_FIND_BASE = `${ROUTE_KEYS.ACCOUNTS}/${ROUTE_KEYS.FIND}`
const ROUTE_PRODUCTS_BASE = `${ROUTE_KEYS.PRODUCTS}/${RouteKeyName.ProductId}`
const ROUTE_MYPAGE_ORDER_CANCEL_BASE = `${ROUTE_KEYS.MYPAGE}/${ROUTE_KEYS.ORDERS}/[orderId]/[cancelType]`
export const ROUTE_ORDER_BASE = `${ROUTE_KEYS.ORDER}`
export const ROUTE_MYPAGE_BASE = `${ROUTE_KEYS.MYPAGE}`
export const ROUTE_MYPAGE_INFO_BASE = `${ROUTE_KEYS.MYPAGE}/${ROUTE_KEYS.INFO}`
export const ROUTE_DELIVERY_ADDRESSES_BASE = `${ROUTE_KEYS.DELIVERY_ADDRESSES}`
export const ROUTE_POLICY_BASE = `${ROUTE_KEYS.POLICY}`
export const ROUTE_MEMBERSHIP_BASE = `${ROUTE_KEYS.MEMBERSHIP}`
export const ROUTE_HOSPITAL_BASE = `${ROUTE_KEYS.HOSPITAL}`

const ROUTES = {
  PREFIX: `/${ROUTE_KEYS.MALL}`,
  INDEX: `${ROUTE_KEYS.INDEX}`,
  BEST_SELLER: `/${ROUTE_KEYS.BEST_SELLER}/${RouteKeyName.BestSellerCategoryName}`,
  HOME: {
    BASE: `/${ROUTE_HOME_BASE}`,
    BEST_SELLER: `/${ROUTE_HOME_BASE}/${ROUTE_KEYS.BEST_SELLER}/${RouteKeyName.BestSellerCategoryName}`,
    EVENTS: {
      BASE: `/${ROUTE_HOME_BASE}/${ROUTE_KEYS.EVENTS}`,
      DETAIL: `/${ROUTE_HOME_BASE}/${ROUTE_KEYS.EVENTS}/${RouteKeyName.AdSlug}`,
    },
    FIND_HOSPITALS: `/${ROUTE_HOME_BASE}/${ROUTE_KEYS.FIND_HOSPITALS}`,
  },
  DELIVERY_ADDRESSES: {
    CREATE: `/${ROUTE_DELIVERY_ADDRESSES_BASE}/${ROUTE_KEYS.CREATE}`,
    UPDATE: `/${ROUTE_DELIVERY_ADDRESSES_BASE}/${ROUTE_KEYS.UPDATE}/[customerTemplateAddressId]`,
  },
  ORDER: {
    BASE: `/${ROUTE_ORDER_BASE}`,
    DELIVERY_ADDRESSES: `/${ROUTE_ORDER_BASE}/${ROUTE_KEYS.DELIVERY_ADDRESSES}`,
    FINISH: `/${ROUTE_ORDER_BASE}/${ROUTE_KEYS.FINISH}`,
    REDIRECT: `/${ROUTE_ORDER_BASE}/${ROUTE_KEYS.REDIRECT}`,
    COUPONS: `/${ROUTE_ORDER_BASE}/${ROUTE_KEYS.COUPONS}`,
    TRANSACTION: `/${ROUTE_ORDER_BASE}/${ROUTE_KEYS.TRANSACTION}`,
    COUPONS_ADD: `/${ROUTE_ORDER_BASE}/${ROUTE_KEYS.COUPONS}/${ROUTE_KEYS.ADD}`,
  },
  ACCOUNTS: {
    LOGIN: `/${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.LOGIN}`,
    WELCOME: `/${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.WELCOME}`,
    EMAIL_LOGIN: `/${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.EMAIL_LOGIN}`,
    REGISTER: `/${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.REGISTER}`,
    SIGN_UP: `/${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.SIGN_UP}`,
    FIND: {
      ID: `/${ROUTE_ACCOUNTS_FIND_BASE}/${ROUTE_KEYS.ID}`,
      PASSWORD: `/${ROUTE_ACCOUNTS_FIND_BASE}/${ROUTE_KEYS.PASSWORD}`,
    },
    ZENDESK: {
      LOGIN: `/${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.ZENDESK}/${ROUTE_KEYS.LOGIN}`,
    },
    RESET_PASSWORD: `/${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.RESET_PASSWORD}`,
    CALLBACK_NAVER: `/${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.SNS_NAVER}/${ROUTE_KEYS.CALLBACK}`,
    CALLBACK_APPLE: `/${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.SNS_APPLE}/${ROUTE_KEYS.CALLBACK}`,
    CALLBACK_KAKAO: `/${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.SNS_KAKAO}/${ROUTE_KEYS.CALLBACK}`,
    NEED_CONVERT: `/${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.NEED_CONVERT}`,
    ADD_INFO: `/${ROUTE_ACCOUNTS_BASE}/${ROUTE_KEYS.ADD_INFO}`,
  },
  PRODUCTS: {
    DETAIL: `/${ROUTE_PRODUCTS_BASE}`,
    DETAIL_OLD: `/${ROUTE_PRODUCTS_BASE}/index-old`,
    EXCHANGE_REFUND_GUIDE: `/${ROUTE_PRODUCTS_BASE}/${ROUTE_KEYS.EXCHANGE_REFUND_GUIDE}`,
    REVIEWS: `/${ROUTE_PRODUCTS_BASE}/${ROUTE_KEYS.REVIEWS}`,
    INQUIRY: `/${ROUTE_PRODUCTS_BASE}/${ROUTE_KEYS.INQUIRY}`,
    INQUIRY_WRITE: `/${ROUTE_PRODUCTS_BASE}/${ROUTE_KEYS.INQUIRY}/${ROUTE_KEYS.WRITE}`,
    BUNDLED_LIST: `/${ROUTE_PRODUCTS_BASE}/${ROUTE_KEYS.BUNDLED_LIST}/[type]`,
  },
  RELATED_LIST: `/${ROUTE_KEYS.RELATED_LIST}`,
  BRAND_DETAIL: `/${ROUTE_KEYS.BRANDS}/${RouteKeyName.BrandId}`,
  SECTION_DETAIL: `/${ROUTE_KEYS.SECTIONS}/[displayCollectionId]`,
  SECTION_RECENTLY_BOUGHT: `/${ROUTE_KEYS.SECTIONS}/recently-bought`,
  SECTION_RECOMMEND_PET: `/${ROUTE_KEYS.SECTIONS}/recommend-pet`,
  SECTION_RECOMMEND_PET_ID: `/${ROUTE_KEYS.SECTIONS}/recommend-pet/[[...petId]]`,
  SECTION_PERSONALIZED_RECOMMEND: `/${ROUTE_KEYS.SECTIONS}/personalized-recommend`,
  CATEGORY: {
    ALL_ROOT: `/${ROUTE_KEYS.CATEGORY}/${ROUTE_KEYS.ALL}/${RouteKeyName.RootCategorySlug}`,
    ROOT: `/${ROUTE_KEYS.CATEGORY}/${RouteKeyName.RootCategorySlug}`,
    SUB: `/${ROUTE_KEYS.CATEGORY}/${RouteKeyName.RootCategorySlug}/${RouteKeyName.SubCategorySlug}`,
  },
  EVENT_DETAIL: `/${ROUTE_KEYS.EVENTS}/${RouteKeyName.AdSlug}`,
  // TODO 100원딜 슬러그 변경 시 pathname, Route Name -> welcome_deal 로 변경 필요 - Jerry 2022/10/17
  EVENTS_100_DEAL: `/${ROUTE_KEYS.EVENTS}/100-won-deal`,
  EVENTS_100_DEAL_CAT: `/${ROUTE_KEYS.EVENTS}/100-won-deal-cat`,
  EVENTS_100_DEAL_DOG: `/${ROUTE_KEYS.EVENTS}/100-won-deal-dog`,
  EVENTS_APP_ONLY: `/${ROUTE_KEYS.EVENTS}/${ROUTE_KEYS.APP_ONLY}`,
  FRIENDS_INVITE: `/${ROUTE_KEYS.FRIENDS}/${ROUTE_KEYS.INVITE}`,
  FRIENDS_GUEST: `/${ROUTE_KEYS.FRIENDS}/${ROUTE_KEYS.GUEST}`,
  EVENTS_PARTNERS: `/${ROUTE_KEYS.EVENTS}/partners`,
  EVENTS_PEPPER: `/${ROUTE_KEYS.EVENTS}/pepper_promotion_02`,
  HOSPITAL: {
    BASE: `${ROUTE_HOSPITAL_BASE}`,
    HOSPITALS: {
      BASE: `${ROUTE_HOSPITAL_BASE}/${ROUTE_KEYS.HOSPITALS}`,
      REVIEWS: {
        BASE: `${ROUTE_HOSPITAL_BASE}/${ROUTE_KEYS.HOSPITALS}/${ROUTE_KEYS.REVIEWS}`,
        MY: {
          BASE: `${ROUTE_HOSPITAL_BASE}/${ROUTE_KEYS.HOSPITALS}/${ROUTE_KEYS.REVIEWS}/${ROUTE_KEYS.MY}`,
          REVIEWS: `${ROUTE_HOSPITAL_BASE}/${ROUTE_KEYS.HOSPITALS}/${ROUTE_KEYS.REVIEWS}/${ROUTE_KEYS.MY}`,
        },
      },
      RECEIPTS: {
        BASE: `${ROUTE_HOSPITAL_BASE}/${ROUTE_KEYS.HOSPITALS}/${ROUTE_KEYS.REVIEWS}/${ROUTE_KEYS.MY}/receipts`,
      },
      INSURANCE: {
        BASE: `${ROUTE_HOSPITAL_BASE}/insurance`,
        INTRO: `${ROUTE_HOSPITAL_BASE}/insurance/intro`,
      },
    },
    MYPAGE: {
      BASE: `${ROUTE_HOSPITAL_BASE}/${ROUTE_KEYS.MYPAGE}`,
      SCRAP: `${ROUTE_HOSPITAL_BASE}/${ROUTE_KEYS.MYPAGE}/${ROUTE_KEYS.SCRAP}`,
    },
    RESERVATION: {
      BASE: `${ROUTE_HOSPITAL_BASE}/${ROUTE_KEYS.RESERVATIONS}`,
      RECEIPTS: `${ROUTE_HOSPITAL_BASE}/${ROUTE_KEYS.RESERVATIONS}/${ROUTE_KEYS.MY}`,
      MIGRATE: `${ROUTE_HOSPITAL_BASE}/${ROUTE_KEYS.RESERVATIONS}/${ROUTE_KEYS.MIGRATE}`,
    },
  },
  CART: `/${ROUTE_KEYS.CART}`,
  SEARCH: `/${ROUTE_KEYS.SEARCH}`,
  SEARCH_RESULT: `/${ROUTE_KEYS.SEARCH}/${ROUTE_KEYS.RESULT}`,
  SEARCH_KEYWORD: `/${ROUTE_KEYS.SEARCH}/${RouteKeyName.PetType}/${RouteKeyName.Keyword}`,
  HEALTH: {
    BASE: '/health',
    INTRO: '/health/intro',
  },
  MYPAGE: {
    BASE: `/${ROUTE_MYPAGE_BASE}`,
    INFO: {
      BASE: `/${ROUTE_MYPAGE_INFO_BASE}`,
      CONFIRM_PASSWORD: `/${ROUTE_MYPAGE_INFO_BASE}/${ROUTE_KEYS.CONFIRM_PASSWORD}`,
      UPDATE_INFO: `/${ROUTE_MYPAGE_INFO_BASE}/${ROUTE_KEYS.UPDATE}`,
      WITHDRAW: `/${ROUTE_MYPAGE_INFO_BASE}/${ROUTE_KEYS.WITHDRAW}`,
      SNS_LINK: `/${ROUTE_MYPAGE_INFO_BASE}/${ROUTE_KEYS.SNS_LINK}`,
      MARKETING_AGREEMENT: `/${ROUTE_MYPAGE_INFO_BASE}/${ROUTE_KEYS.MARKETING_AGREEMENT}`,
      VERSION: `/${ROUTE_MYPAGE_INFO_BASE}/${ROUTE_KEYS.VERSION}`,
      DELIVERY_ADDRESSES: {
        BASE: `/${ROUTE_MYPAGE_INFO_BASE}/${ROUTE_KEYS.DELIVERY_ADDRESSES}`,
        CREATE: `/${ROUTE_MYPAGE_INFO_BASE}/${ROUTE_KEYS.DELIVERY_ADDRESSES}/${ROUTE_KEYS.CREATE}`,
        UPDATE: `/${ROUTE_MYPAGE_INFO_BASE}/${ROUTE_KEYS.DELIVERY_ADDRESSES}/${ROUTE_KEYS.UPDATE}/[customerTemplateAddressId]`,
      },
    },
    RETRIEVAL_ADDRESSES: `/${ROUTE_MYPAGE_BASE}/${ROUTE_KEYS.RETRIEVAL_ADDRESSES}`,
    REFUND_ACCOUNT: `/${ROUTE_MYPAGE_BASE}/${ROUTE_KEYS.REFUND_ACCOUNT}`,
    REFUND_ACCOUNT_EDIT: `/${ROUTE_MYPAGE_BASE}/${ROUTE_KEYS.REFUND_ACCOUNT}/${ROUTE_KEYS.EDIT}`,
    ORDERS: `/${ROUTE_MYPAGE_BASE}/${ROUTE_KEYS.ORDERS}`,
    ORDER_DETAIL: `/${ROUTE_MYPAGE_BASE}/${ROUTE_KEYS.ORDERS}/[orderId]`,
    ORDER_CANCEL: {
      BASE: `/${ROUTE_MYPAGE_ORDER_CANCEL_BASE}`,
      REASON: `/${ROUTE_MYPAGE_ORDER_CANCEL_BASE}/${ROUTE_KEYS.REASON}`,
      REQUEST: `/${ROUTE_MYPAGE_ORDER_CANCEL_BASE}/${ROUTE_KEYS.REQUEST}`,
      DONE: `/${ROUTE_MYPAGE_ORDER_CANCEL_BASE}/${ROUTE_KEYS.DONE}`,
    },
    FREQUENTLY_LIST: `/${ROUTE_MYPAGE_BASE}/${ROUTE_KEYS.FREQUENTLY_LIST}`,
    COUPONS: `/${ROUTE_MYPAGE_BASE}/${ROUTE_KEYS.COUPONS}`,
    COUPONS_ADD: `/${ROUTE_MYPAGE_BASE}/${ROUTE_KEYS.COUPONS}/${ROUTE_KEYS.ADD}`,
    CANCEL_RETURN_EXCHANGE_LIST: `/${ROUTE_MYPAGE_BASE}/${ROUTE_KEYS.CANCEL_RETURN_EXCHANGE}`,
    CANCEL_RETURN_EXCHANGE_PROCESSING_LIST: `/${ROUTE_MYPAGE_BASE}/${ROUTE_KEYS.CANCEL_RETURN_EXCHANGE}/${ROUTE_KEYS.PROCESSING}`,
    CANCEL_RETURN_EXCHANGE_PROCESSED_LIST: `/${ROUTE_MYPAGE_BASE}/${ROUTE_KEYS.CANCEL_RETURN_EXCHANGE}/${ROUTE_KEYS.PROCESSED}`,
    REVIEWS: {
      LIST: `/${ROUTE_MYPAGE_BASE}/${ROUTE_KEYS.REVIEWS}`,
      WRITE: `/${ROUTE_MYPAGE_BASE}/[orderItemId]/${ROUTE_KEYS.REVIEWS}/${ROUTE_KEYS.WRITE}`,
      EDIT: `/${ROUTE_MYPAGE_BASE}/${ROUTE_KEYS.REVIEWS}/[reviewId]/${ROUTE_KEYS.EDIT}`,
    },
    MILEAGES: `/${ROUTE_MYPAGE_BASE}/${ROUTE_KEYS.MILEAGES}`,
    FAVORITE_LIST: {
      BASE: `/${ROUTE_MYPAGE_BASE}/favorites`,
      LIKE: `/${ROUTE_MYPAGE_BASE}/favorites?tab=like`,
      VIEW: `/${ROUTE_MYPAGE_BASE}/favorites?tab=view`,
    },
  },
  POLICY: {
    AGREEMENT: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.AGREEMENT}`,
    PRIVACY: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.PRIVACY}`,
    LOCATION_SERVICE: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.LOCATION_SERVICE}`,
    PROVIDE_3RD: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.PROVIDE_3RD}`,
    PAYMENT_GUARANTEE: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.PAYMENT_GUARANTEE}`,
    PRIVACY_PURPOSE: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.PRIVACY_PURPOSE}`,
    SELLERS: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.SELLERS}`,
    HOSPITALS: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.HOSPITALS}`,
    BEFORE_PRIVACY_20220719: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.BEFORE_PRIVACY_20220719}`,
    BEFORE_PRIVACY_20221004: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.BEFORE_PRIVACY_20221004}`,
    BEFORE_PRIVACY_20230424: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.BEFORE_PRIVACY_20230424}`,
    BEFORE_PRIVACY_20230628: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.BEFORE_PRIVACY_20230628}`,
    BEFORE_PRIVACY_20230731: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.BEFORE_PRIVACY_20230731}`,
    BEFORE_PRIVACY_20231031: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.BEFORE_PRIVACY_20231031}`,
    BEFORE_PRIVACY_20231115: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.BEFORE_PRIVACY_20231115}`,
    BEFORE_PRIVACY_20241201: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.BEFORE_PRIVACY_20241201}`,
    BEFORE_LOCATION_PRIVACY_20220125: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.BEFORE_LOCATION_PRIVACY_20220125}`,
    BEFORE_LOCATION_PRIVACY_20230424: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.BEFORE_LOCATION_PRIVACY_20230424}`,
    POLICY_MARKETING: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.POLICY_MARKETING}`,
    OPTIONAL_PRIVACY_TO_COLLECT: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.OPTIONAL_PRIVACY_TO_COLLECT}`,
    FITPET_PLUS_TERMS: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.FITPET_PLUS_TERMS}`,
    FITPET_PLUS_PRIVACY: `/${ROUTE_POLICY_BASE}/${ROUTE_KEYS.FITPET_PLUS_PRIVACY}`,
    PARTNERS: `/${ROUTE_POLICY_BASE}/partners`,
  },
  MEMBERSHIP: {
    INDEX: `/${ROUTE_MEMBERSHIP_BASE}`,
    INTRO: `/${ROUTE_MEMBERSHIP_BASE}/${ROUTE_KEYS.INTRO}`,
    JOIN: {
      OWNER: {
        BASE: `/${ROUTE_MEMBERSHIP_BASE}/${ROUTE_KEYS.JOIN}/${ROUTE_KEYS.OWNER}`,
        FINISH: `/${ROUTE_MEMBERSHIP_BASE}/${ROUTE_KEYS.JOIN}/${ROUTE_KEYS.OWNER}/${ROUTE_KEYS.FINISH}`,
        REGULAR_PAYMENT: `/${ROUTE_MEMBERSHIP_BASE}/${ROUTE_KEYS.JOIN}/${ROUTE_KEYS.OWNER}/${ROUTE_KEYS.REGULAR_PAYMENT}`,
      },
      FAMILY: {
        BASE: `/${ROUTE_MEMBERSHIP_BASE}/${ROUTE_KEYS.JOIN}/${ROUTE_KEYS.FAMILY}`,
        FINISH: `/${ROUTE_MEMBERSHIP_BASE}/${ROUTE_KEYS.JOIN}/${ROUTE_KEYS.FAMILY}/${ROUTE_KEYS.FINISH}`,
      },
      SUBSCRIBE: `/${ROUTE_MEMBERSHIP_BASE}/${ROUTE_KEYS.JOIN}/${ROUTE_KEYS.SUBSCRIBE}`,
      SUBSCRIBE_FINISH: `/${ROUTE_MEMBERSHIP_BASE}/${ROUTE_KEYS.JOIN}/${ROUTE_KEYS.SUBSCRIBE_FINISH}`,
    },
    SETTING: {
      BASE: `/${ROUTE_MEMBERSHIP_BASE}/${ROUTE_KEYS.SETTING}`,
      INFO: `/${ROUTE_MEMBERSHIP_BASE}/${ROUTE_KEYS.SETTING}/${ROUTE_KEYS.INFO}`,
      FAMILY: `/${ROUTE_MEMBERSHIP_BASE}/${ROUTE_KEYS.SETTING}/${ROUTE_KEYS.FAMILY}`,
      MILEAGE: `/${ROUTE_MEMBERSHIP_BASE}/${ROUTE_KEYS.SETTING}/${ROUTE_KEYS.MILEAGE}`,
      CANCEL: {
        REQUEST: `/${ROUTE_MEMBERSHIP_BASE}/${ROUTE_KEYS.SETTING}/${ROUTE_KEYS.CANCEL}/${ROUTE_KEYS.REQUEST}`,
        REASON: `/${ROUTE_MEMBERSHIP_BASE}/${ROUTE_KEYS.SETTING}/${ROUTE_KEYS.CANCEL}/${ROUTE_KEYS.REASON}`,
        FINISH: `/${ROUTE_MEMBERSHIP_BASE}/${ROUTE_KEYS.SETTING}/${ROUTE_KEYS.CANCEL}/${ROUTE_KEYS.FINISH}`,
      },
      HISTORY: `/${ROUTE_MEMBERSHIP_BASE}/${ROUTE_KEYS.SETTING}/${ROUTE_KEYS.HISTORY}`,
    },
    RECOMMEND: `/${ROUTE_MEMBERSHIP_BASE}/${ROUTE_KEYS.RECOMMEND}`,
  },
  PARTNERS: {
    REGISTER: `/partners/register`,
    HOME: '/partners/home',
    REWARD: '/partners/reward',
    GUIDE: '/events/partners_guide',
    FAQ: '/events/partners_faq',
    COMPLETED: '/partners/completed',
  },
  NOT_FOUND: `/404`,
  BLOG: 'https://fitpetmall.com/blog',
  BLOG_WWW: 'https://www.fitpetmall.com/blog',
  PLAY: {
    HOME: `${process.env.HOSPITAL_HOST}/play/home`,
  },
}

const AUTH_ROUTE = [ROUTE_KEYS.ORDER, ROUTE_KEYS.DELIVERY_ADDRESSES, ROUTE_KEYS.MYPAGE, ROUTE_KEYS.ACCOUNTS]

const checkAuthRoute = (path: string) => {
  const pathname = new URL(path).pathname.replace('/mall', '')
  return AUTH_ROUTE.includes(pathname.split('/')[1])
}

export { AUTH_ROUTE, checkAuthRoute }
export default ROUTES
